<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Ongoing Orders</span>
    </el-card>
    <div class="table-container">
      <el-table
        ref="table"
        style="width: 100%"
        :data="header"
        v-loading="listLoading"
        border
      >
        <el-table-column label="Captain Name">
          <template slot-scope="scope">{{ scope.row.captainName }}</template>
        </el-table-column>
        <el-table-column label="Captain Address">
          <template slot-scope="scope">{{ scope.row.captainAddress }}</template>
        </el-table-column>
        <el-table-column label="Orders count">
          <template slot-scope="scope">{{ scope.row.orderTotal }}</template>
        </el-table-column>
        <el-table-column label="Bulk Status">
          <template slot-scope="scope">{{ scope.row.bulkStatus }}</template>
        </el-table-column>
        <el-table-column label="Operations">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              v-if="scope.row.bulkStatus == 'Created' || scope.row.bulkStatus == 'In progress'"
              @click="terminate()"
              >Terminate
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-container">
      <el-table
        ref="table"
        style="width: 100%"
        :data="list"
        v-loading="listLoading"
        border
      >
        <el-table-column label="User Name">
          <template slot-scope="scope">{{ scope.row.nickname }}</template>
        </el-table-column>
        <el-table-column label="Email">
          <template slot-scope="scope">{{ scope.row.email }}</template>
        </el-table-column>
        <el-table-column label="Ordered At">
          <template slot-scope="scope">{{
            new Date(scope.row.orderedAt).toString()
          }}</template>
        </el-table-column>
        <el-table-column label="Order Number">
          <template slot-scope="scope">{{ scope.row.orderSn }}</template>
        </el-table-column>
        <el-table-column label="Amount">
          <template slot-scope="scope">{{ scope.row.amount }}</template>
        </el-table-column>
        <el-table-column label="Payment Status">
          <template slot-scope="scope">{{ scope.row.paymentStatus }}</template>
        </el-table-column>
        <el-table-column label="Operations">
          <template slot-scope="scope">
            <el-button size="mini" @click="showDetail(scope.row)"
              >Details</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { fetchOrders, terminate as terminateBulk } from '@/api/bulk';

export default {
  name: 'ongoingOrder',
  created() {
    this.getList();
  },
  data() {
    return {
      list: null,
      listLoading: true,
      header: null,
    };
  },
  methods: {
    getList() {
      if (this.$route.query.id == null) return false;
      fetchOrders(this.$route.query.id).then((response) => {
        this.list = response.data.data;
        this.header = [response.data];
        this.listLoading = false;
      });
    },
    terminate(id) {
      this.$confirm('Terminate Bulk?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'Warning',
      }).then(() => {
        terminateBulk(this.$route.query.id).then(() => {
          this.$message({
            message: 'Success',
            type: 'success',
            duration: 1000,
          });
          this.getList();
        });
      });
    },
  },
};
</script>
